<template>
  <div class="bac">
    <div style="text-align: center">
      <el-card shadow="hover" style="width: 10rem">
        <el-table
            :data="projects"
            highlight-current-row
            max-height="700"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="项目名称" prop="projectName"></el-table-column>
          <el-table-column label="发布时间" prop="publishTime"></el-table-column>
          <el-table-column label="设计领域" prop="typeSecond" ></el-table-column>
          <el-table-column label="操作" prop="" >
            <template #default="scope">
               <el-button @click="this.$router.push({path:'/ProjectDetail',query: {projectId: scope.row.id}})">查看详情</el-button>
              <el-button v-show="false" type="primary">通过</el-button>
              <el-button v-show="false" type="danger">拒绝</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>


</template>

<script>
export default {
  name: "AuditingProjects",
  data(){
    return{
      projects:[],
      total: 0,
      positionTemp:[],
    }
  },
  mounted() {
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'get',
        url: '/admin/pendingProjects',
        data: '',
      }).then(res => {
        this.projects = res.data;
      })
    },
  }
}
</script>

<style scoped>
.bac{
  height: 10.8rem;
  width: 19.2rem;
}
</style>